'use client';

import { useEffect } from 'react';

import { setTacticIdToSession } from '@/utils/tacticId/setTacticIdToSession';

import { TacticIdDataType } from '@/utils/tacticId/types';

const TacticId = ({ tacticIdData }: { tacticIdData: TacticIdDataType }) => {
  useEffect(() => {
    if (tacticIdData) {
      setTacticIdToSession(tacticIdData);
    }
  }, [tacticIdData]);

  return null;
};

export default TacticId;
