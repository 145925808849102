import { isWindowDefined } from '../isWindowDefined';
import { TACTIC_ID } from './constants';
import { TacticIdDataType } from './types';
import { getPersistedTacticId, setPersistedTacticId } from './utils';

export const getTacticType = (
  config: TacticIdDataType,
  referrer?: string,
): string => {
  const { excludedInternalOrigins, internalOrigins, searchEngines } = config;

  if (!referrer) {
    return 'DIRECT';
  }

  if (searchEngines.some(domain => referrer.includes(domain))) {
    return 'ORGANIC';
  }

  const isInternalOrigin = internalOrigins.some(internalOrigin =>
    referrer.includes(internalOrigin),
  );
  const isExcludedInternalOrigin = excludedInternalOrigins.some(
    excludedOrigin => referrer.includes(excludedOrigin),
  );

  if (isInternalOrigin && !isExcludedInternalOrigin) {
    return 'INTERNAL_REFERRAL';
  }

  return 'EXTERNAL_REFERRAL';
};

export const getTacticIdByReferrer = (
  config: TacticIdDataType,
  referrer?: string,
): string | undefined => {
  const { trafficTypes } = config;
  const tacticType = getTacticType(config, referrer);

  return trafficTypes.find(trafficType => trafficType.name === tacticType)
    ?.tacticId;
};

export const getTacticId = (config: TacticIdDataType): string | undefined => {
  const referrer = document.referrer;
  const tacticId = new URLSearchParams(window.location.search).get(TACTIC_ID);
  return tacticId || getTacticIdByReferrer(config, referrer);
};

export const setTacticIdToSession = (config: TacticIdDataType) => {
  if (getPersistedTacticId() || !isWindowDefined()) return;

  const tacticId = getTacticId(config);
  if (!tacticId) return;

  setPersistedTacticId(tacticId);
};
