'use client';

import React, { ReactNode } from 'react';
import { AbstractIntlMessages, NextIntlClientProvider } from 'next-intl';

type Props = {
  locale: string;
  messages: AbstractIntlMessages;
  children: ReactNode;
};

const NextIntlClientProviderWrapper = ({
  locale,
  messages,
  children,
}: Props) => {
  return (
    <NextIntlClientProvider
      messages={messages}
      locale={locale}
      // TODO: Fix Intl Errors
      onError={() => null}
    >
      {children}
    </NextIntlClientProvider>
  );
};

export default NextIntlClientProviderWrapper;
